/* eslint-disable */
export const env = {
  "BASE_URL": "https://event-api-dev.momice.com/api/eventwebsite",
  "SENTRY_DSN": "https://8ac9d0552cbc4c9d1ccb38ef0da9d54d@o4506298473709568.ingest.sentry.io/4506416018751488",
  "ANON_CONFIG_1": null,
  "CHAT_SERVER": "https://chat-dev.momice.com:3001",
  "VOTING_SERVER": "https://voting-dev.momice.com:3002",
  "ADYEN_CLIENT_KEY": "test_FMISOF3MFJFKZBBMT4VIFF2ZVA5RNS7J",
  "ADYEN_ENVIRONMENT": "test",
  "NODE_ENV_SENTRY": "development",
  "API_URL": "https://event-api-dev.momice.com",
  "API_CONTENT_URL": "https://event-api-dev.momice.com",
  "ADYEN_ENDPOINT": "https://test.adyen.com/hpp/pay.shtml",
  "X_MOMICE_VERSION": "1.0.0-c2584fd"
}
/* eslint-enable */
